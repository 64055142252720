@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');

:root {
  --primary: #625df5;
  --gradient: linear-gradient(to right, #625df5, #413da2);
}

@font-face {
  font-family: "Nordique Pro Bold";
  src: url("/public/fonts/Nordique-Pro-Bold.woff")format("woff"),
    url("/public/fonts/Nordique-Pro-Bold.woff2")format("woff2"),
    url("/public/fonts/Nordique-Pro-Bold.ttf")format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: #171923;
  color: white;
  margin: 0;
  padding: 0;
}

body * {
  font-family: 'Quicksand', sans-serif !important;
}

.logo-text {
  font-weight: 700px !important;
}

*::-webkit-scrollbar {
  width: 5px !important;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary) !important;
  outline: 0px solid rgba(112, 128, 144, 0) !important;
  border-radius: 100px;
}


.maxHeight100 {
  max-height: 100vh;
}

.overflowY-scroll {
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.overflowHidden {
  overflow: hidden !important;
}

a {
  text-decoration: none !important;
}

div.logo img {
  width: 150px;
}

div.logo-icon img {
  width: 30px;
}

.invert {
  filter: invert(1);
}

.border-radius-20 {
  border-radius: 20px !important;
}

.border20 {
  border-radius: 20px !important;
}

.bold {
  font-weight: 700;
}

.margin0 {
  margin: 0px !important;
}

.pointer {
  cursor: pointer;
}

::selection {
  background: var(--primary);
  color: white;
}

.hoverup {
  transition: .5s ease;
}

.fixed {
  position: fixed;
  left: 0;
}

.bottom0 {
  bottom: 0;
}

.top0 {
  top: 0;
}

.glass {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.155);
}

.hoverup:hover {
  transform: translateY(-5px);
}

.primary {
  background-color: var(--primary);
}

.primary-text {
  color: var(--primary);
}

.gradient {
  background: var(--gradient);
}

.gradient-animate {
  background: var(--gradient);
  background-size: 400%;
  animation: gradient 20s infinite;
}

@keyframes gradient {

  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.mid {
  width: 600px;
}

.small {
  width: 300px;
}

.full {
  height: 100vh;
}

.fullwidth {
  width: 100%;
}

.hide {
  display: none !important;
}

.shadow {
  box-shadow: 0px 10px 20px #f0f0f0;
}

.margin-auto {
  margin: 0px auto !important;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0em !important;
}

.hide-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0) !important;
  outline: 0px solid rgba(112, 128, 144, 0) !important;
}

.seventy-percent {
  width: 70% !important;
}

.pulse {
  position: relative;
}

.pulse>* {
  z-index: 2;
}

.pulse::after {
  content: '';
  background: var(--gradient);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .3;
  animation: pulse 2s ease-in infinite;
  border-radius: 10000px;
  z-index: -1;
}

fieldset,
label {
  color: white !important;
}

fieldset {
  border-color: white !important;
  border-radius: 10px !important;
}

input,
textarea {
  color: white !important;
}

select {
  width: calc(100%);
  background: transparent !important;
  padding: 10px;
  color: white;
  border-radius: 10px;
  border: 1px solid white;
}

select:active {
  background-image: linear-gradient(to right, #171923, #171923) !important;
  color: white !important;
  background-color: #171923 !important;
}

select:hover {
  background-image: linear-gradient(to right, #171923, #171923) !important;
  color: white !important;
  background-color: #171923 !important;
}

.mock-input {
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px;
  width: calc(100% - 10px);
}

.mock-input input {
  background-color: transparent;
  border: 0px solid transparent;
}

.mock-input input:focus {
  outline: 0px solid transparent;
  border: 0px solid transparent
}

.twitter-picker input {
  color: var(--primary) !important;
}

@keyframes pulse {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.1, 1.4);
    opacity: 0;
  }
}

.by-nick {
  z-index: 2;
  position: relative;
}

.anim {
  position: absolute;
  right: 0px;
  top: 0;
  overflow: hidden;
  height: 100%;
  z-index: 1;
}

.anim img {
  width: 400px;
  display: block;
  animation: scroll 200s linear infinite;
  opacity: .3;
}

.not-found-text {
  padding-right: 12px;
  animation: type .5s alternate infinite;
}

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #888;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}

@keyframes scroll {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-3000px);
  }
}

@media screen and (max-width: 600px) {
  h3 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 900px) {
  .hide-on-med-and-down {
    display: none !important;
  }

  .vertical-scroll {
    overflow-y: scroll;
    max-height: 600px;
    overflow-x: hidden;
  }

  .no-grid-small {
    display: block !important;
  }
}

@media screen and (min-width: 901px) {
  .hide-on-large-only {
    display: none !important;
  }
}